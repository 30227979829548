<template>
 <div class="login-v">
    <a-spin :spinning="spinnings" :delay="delayTime">
      <a-icon slot="indicator" type="loading" style="font-size: 24px" spin/>
        <a-form-model class="login-f" ref="ruleForm" :model="ruleForm" :rules="rules" v-bind="layout">
          <a-form-model-item has-feedback label="用户" prop="name">
            <a-input v-model="ruleForm.name" />
          </a-form-model-item>

          <a-form-model-item has-feedback label="密码" prop="pwd">
            <a-input v-model="ruleForm.pwd" type="password"  />
          </a-form-model-item>

          <a-form-model-item :wrapper-col="{ span: 8, offset: 8 }">

            <a-button type="primary" @click="submitForm('ruleForm')">
              登录
            </a-button>

            <a-button style="margin-left: 10px" @click="resetForm('ruleForm')">
              清空
            </a-button>
          </a-form-model-item>
        </a-form-model>
    </a-spin>
 </div>
</template>

<script>
import {loginApi} from '@/api/login'
import {setToken} from '@/utils/TokenCache'
export default {
  name: "login",
  data(){
    return{
      spinnings: false,
      delayTime: 100,
      ruleForm: {
        name: '',
        pwd: '',
      },
      rules: {
        name: [{ required: true, trigger: 'blur',message: '用户名不能为空' }],
        pwd: [{ required: true, trigger: 'blur',message: '密码不能为空' }],

      },
      layout: {
        labelCol: { span: 8 },
        wrapperCol: { span: 8},
      },
    }
  },
  methods:{
    // 2022-09-08更新 -Seri
    loginSuccess(body){
        let appId = body.id;
        let customerId = body.customerId;
      // localStorage:localStorage的生命周期是永久的，关闭页面或浏览器之后localStorage中的数据也不会消失
        localStorage.setItem('customer_id', customerId);//每次登录成功，都缓存当前客户id
        /*this.$router.push(
            {
              path:'/index',
              query:{
                app_id: appId,
                customer_id: customerId
              }
            }
        )*/
        this.$router.push({path:'/index'});
      },
    // loginSuccess(){
    //     this.$router.push({path:'/index'});
    //   },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.spinnings = true
          loginApi(this.ruleForm).then(response => {
            // console.log(response);
            if(!response){
              this.spinnings = false
              this.$message('登录失败 服务器响应空');
              return;
            }
            let body = response.body;
            if(body){
              this.spinnings = false
              setToken(body.token)
              sessionStorage.setItem("unameDes",response.body.name);
              this.loginSuccess(body);
            }else{
              this.spinnings = false
              this.$message.error('用户名或密码有误');
            }
          })
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  }
}
</script>

<style scoped>
.login-v{
  width: 700px;
  height: 600px;
  /*background: #7b4031;*/
  margin: 0 auto;
  padding-top: 220px;
}
.login-f{

  /*background-color: crimson;*/
}
</style>
