import request from '@/utils/request'

export function loginApi(data) {
    return request({
        url: '/advert/des/accountCustomer/loginDes',
        method: 'post',
        data
    })
}

